import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

// Styles
import "./skodel-services.css";

// Components
import IndexLayout from "../layouts";
import Page from "../components/Page";
import StackedImage from "../components/StackedImage";
import FAQ from "../components/FAQ";
import BookDiscussion from "../components/BookDiscussion";

// Images and icons
import ServiceHero from "../assets/images/report-audit-hero.jpg";
import ServiceCardBG from "../assets/images/leading-card-bg.png";
import IconUsersWhite from "../assets/icons/icon-users-white.svg";
import IconUsersDark from "../assets/icons/icon-users-dark.svg";
import IconMessageWhite from "../assets/icons/icon-message-white.svg";
import IconMessageDark from "../assets/icons/icon-message-dark.svg";
import IconDecreaseWhite from "../assets/icons/icon-decrease-white.svg";
import IconDecreaseDark from "../assets/icons/icon-decrease-dark.svg";
import ReportConfigurableReporting from "../assets/images/report-configurable-reporting.png";
import ReportConfigurableReportingSetting from "../assets/images/report-configurable-reporting-setting.png";
import ReportMultiLevel from "../assets/images/report-multi-level.png";
import ReportExportingIntegration from "../assets/images/report-exporting-integration.png";
import ReportInitiativeImpact from "../assets/images/report-initiative-impact.jpg";
import ServiceStackedImage from "../assets/images/report-effective-transparent.jpg";

// @ts-ignore
const NavLink = (props: any) => <Link activeClassName="active" {...props} />;

const ServicesValueData = [
  {
    id: "SV0001",
    imageWhite: IconUsersWhite,
    imageDark: IconUsersDark,
    title: "Can't report to stakeholders",
    description:
      "Reporting on mental health to stakeholders helps identify concerns and collaboratively implement strategies to address them. Not reporting on mental health leaves an incomplete picture of the organisation's performance and potential risks.",
  },
  {
    id: "SV0002",
    imageWhite: IconMessageWhite,
    imageDark: IconMessageDark,
    title: "Can't measure impact",
    description:
      "Not being able to report on mental health means leaders can't measure the impact of their programs alongside key indicators such as reduction in burnout. This makes it challenging to determine effectiveness and make the right adjustments to improve.",
  },
  {
    id: "SV0003",
    imageWhite: IconDecreaseWhite,
    imageDark: IconDecreaseDark,
    title: "Can't demonstrate commitment",
    description:
      "Not reporting on initiatives and progress can impact internal and external perception of your organisation as neglecting mental health. This can hinder attracting the talent of those who prioritise mental health.",
  },
];

const FAQData = [
  {
    id: "FAQ0001",
    question: "Can data be exported?",
    answer: "Yes, it can be exported as a CSV.",
  },
  {
    id: "FAQ0002",
    question: "Who can see what data?",
    answer:
      "There are two primary account types. Organisation leaders and group leaders. Organisation leaders see data for everyone while group leaders see the data for users in their group.",
  },
  {
    id: "FAQ0003",
    question: "Can data be integrated with other systems?",
    answer:
      "Yes, it can. Please contact us to explore a deeper integration with your systems to visualise data the way that works best for you.",
  },
];

const SkodelReport = () => {
  const [servicesValue, setServicesValue] = useState<
    {
      id: string;
      imageWhite: string;
      imageDark: string;
      title: string;
      description: string;
    }[]
  >([]);
  const [faq, setFAQ] = useState<
    { id: string; question: string; answer: string }[]
  >([]);

  useEffect(() => {
    setServicesValue(ServicesValueData);
    setFAQ(FAQData);
  }, [setServicesValue, setFAQ]);

  return (
    <IndexLayout withBlackLogo={true} workplacesHeader={false}>
      <Helmet>
        <title>Skodel</title>
        <meta
          property="og:title"
          content="Skodel - Report on psychological safety and support your people"
        />
        <meta
          property="og:description"
          content="Skodel's wellbeing and safety platform helps leaders with a higher duty of care meet requirements and support their people."
        />
      </Helmet>
      <Page>
        <section className="section-service-hero">
          <div
            className="section-bg"
            style={{ backgroundImage: `url('${ServiceHero}')` }}
          ></div>
          <div className="main-container">
            <div className="service-hero-content">
              <h1>Skodel Report</h1>
              <p>
                Configurable dashboards, advanced filters, alerts, trend reports
                and wellbeing impact reports for leaders to report on mental
                wellbeing to key stakeholders
              </p>
              <div className="service-hero-button">
                <NavLink
                  to={"/request-a-demo"}
                  className="btn btn-white btn-hero-cta"
                >
                  Book A Demo
                </NavLink>
                <a
                  href="https://skodel.notion.site/Skodel-Report-d923d13aa7954d139609418bca1f76f9?pvs=4"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-outline-white btn-hero-cta"
                >
                  Find Out More
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="section-service-middle">
          <div className="main-container">
            <div className="service-content-wrapper">
              <div className="service-value">
                <div className="section-title text-center">
                  <h2>The problem</h2>
                  <p>
                    Leaders can’t easily report on mental health trends and the
                    impact of their initiatives on mental health and wellbeing.
                  </p>
                </div>
                <div className="service-value-grid column-3">
                  {servicesValue?.map((item, index) => (
                    <div className="service-card" key={item?.id + "-" + index}>
                      <img src={ServiceCardBG} alt={"Skodel"} />
                      <div className="service-icon">
                        <img src={item?.imageWhite} alt={"Skodel"} />
                        <img src={item?.imageDark} alt={"Skodel"} />
                      </div>
                      <div className="service-description">
                        <h3>{item?.title}</h3>
                        <p>{item?.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="service-zigzag-content">
                <div className="service-zigzag-wrapper report-configurable">
                  <div className="service-zigzag-image">
                    <img src={ReportConfigurableReporting} alt={"Skodel"} />
                    <div className="report-configurable-reporting">
                      <img
                        src={ReportConfigurableReportingSetting}
                        alt={"Skodel"}
                      />
                    </div>
                  </div>
                  <div className="service-zigzag-description">
                    <h3>Configurable reporting dashboards</h3>
                    <p>
                      Determine key mental health metrics relevant to each
                      stakeholder and create dashboard views with advanced
                      filtering for these stakeholders. Produce reports on
                      mental health across different levels of your
                      organisation. Configurable dashboards enable you to build
                      out multiple views and export these views as CSVs or PDFs
                      so they can be shared with the right people. Report on
                      mental health across individuals, groups and the whole
                      organisation to identify successful approaches and areas
                      in need of enhancement.
                    </p>
                  </div>
                </div>
                <div className="service-zigzag-wrapper">
                  <div className="service-zigzag-image">
                    <img src={ReportMultiLevel} alt={"Skodel"} />
                  </div>
                  <div className="service-zigzag-description">
                    <h3>Multi level reporting functionality</h3>
                    <p>
                      Deliver the right reports to the right people. User
                      grouping functionality and role based visibility and
                      permissions allows different stakeholders to view
                      different sets of data based on what is important to them.
                      Setup alerts to flag important information and make sure
                      this information is effectively communicated to the right
                      people in your organisation. With Skodel, each leader in
                      your organisation receives the information they need to
                      perform at their best.
                    </p>
                  </div>
                </div>
                <div className="service-zigzag-wrapper">
                  <div className="service-zigzag-image">
                    <img src={ReportExportingIntegration} alt={"Skodel"} />
                  </div>
                  <div className="service-zigzag-description">
                    <h3>Exporting and integration capabilities</h3>
                    <p>
                      View the data in the systems you are used to. Skodel
                      maintains an open API so you can pull and visualise data
                      inside the platform you use as a central reporting
                      dashboard. Exporting capabilities allow you to run reports
                      and export data to the systems you use to analyse and
                      visualise data. With Skodel, you don't have to learn a new
                      system, we work with the way you and your organisation
                      work best.
                    </p>
                  </div>
                </div>
                <div className="service-zigzag-wrapper">
                  <div className="service-zigzag-image">
                    <img src={ReportInitiativeImpact} alt={"Skodel"} />
                  </div>
                  <div className="service-zigzag-description">
                    <h3>Report on the impact of your initiatives</h3>
                    <p>
                      Document the actions your organisation is taking and track
                      their impact alongside key metrics such as burnout. Share
                      this with the wider community and key stakeholders to
                      drive higher engagement in your initiatives. Reporting on
                      the impact of your initiatives helps leaders demonstrate
                      the value of these programs, informs strategic changes and
                      delivers a clear message to everyone that the organisation
                      prioritises mental health.
                    </p>
                  </div>
                </div>
              </div>
              <div className="stacked-content">
                <StackedImage
                  image={ServiceStackedImage}
                  title={"Effective and transparent reporting"}
                  description={
                    "Producing a clear report on mental health can shift internal and external perceptions of an organisation. It's an opportunity to make necessary adjustments, share success stories, highlight visible efforts and create a culture of openness and transparency. An opportunity to show commitment to those who value mental health and those who assess organisations based on this criteria. Skodel gives leaders necessary tools to collect important mental health data and convert it into easily understandable reports for relevant stakeholders."
                  }
                />
              </div>
              <div className="service-faq-wrapper">
                <div className="section-title text-center">
                  <h2>Frequently asked questions</h2>
                </div>
                <div className="service-faq-content">
                  <div className="service-faq-list">
                    {faq?.map((item, index) => (
                      <FAQ
                        key={item?.id + "-" + index}
                        question={item?.question}
                        answer={item?.answer}
                      />
                    ))}
                  </div>
                  <BookDiscussion
                    title={"Book in a discussion"}
                    link={"/request-a-demo"}
                    button={"Request A Demo"}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Page>
    </IndexLayout>
  );
};

export default SkodelReport;
